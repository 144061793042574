import React, { useState } from 'react';
import PropTypes from 'prop-types';

async function loginUser(credentials) {
  return fetch('api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login({setToken}) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    if(token) {
      console.log(token);
      setToken(token);
    }
    
  }

  return(
    <div className="cards-wrapper" style={{ "justify-content": "center" , "margin-top": "200px"}}>
    <form className="card" onSubmit={handleSubmit}>
    <label>
      <p>Username</p>
      <input type="text" onChange={e => setUserName(e.target.value)}/>
    </label>
    <label>
      <p>Password</p>
      <input type="password" onChange={e => setPassword(e.target.value)}/>
    </label>
    <div className="btn-wrap" >
    <button className="btn" type="submit">Submit</button>
    </div>
  </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}