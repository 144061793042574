import React, { Fragment } from "react";
import { IoRadioButtonOffOutline } from "react-icons/io5";
import { BsFillFolderSymlinkFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";

function Project({
  symlinks,
  project,
  sessionLaunched,
  proxy,
  ip,
  type,
  status,
  hSuccesses,
  hErrors,
  hBounced,
  hTotal,
  dSuccesses,
  dErrors,
  dBounced,
  dTotal,
  ySuccesses,
  yErrors,
  yBounced,
  yTotal,
  wSuccesses,
  wErrors,
  wBounced,
  wTotal,
  mSuccesses,
  mErrors,
  mBounced,
  mTotal,
  lSuccesses,
  lErrors,
  lBounced,
  lTotal,
  bSuccesses,
  bErrors,
  bBounced,
  bTotal,
}) {
  const ColoredLine = ({ text }) => (
    <div className="separator">
      <div className="line"></div>
      <p
        data-tip="project"
        data-for={text + ip}
        data-type="info"
        style={{ color: "#ef8742" }}
      >
        {text}
      </p>
      <div className="line"></div>
      <ReactTooltip id={text + ip} />
    </div>
  );

  function renderSymlinks() {
    if (symlinks) {
      return symlinks.map((sml, index) => (
        <a
          rel={"external"}
          className="link"
          target="_blank"
          href={"http://" + sml}
          data-tip={`png: ${index + 1}`}
          data-for={sml}
          data-place="top"
          data-type="info"
        >
          <BsFillFolderSymlinkFill />
          <ReactTooltip id={sml} />
        </a>
      ));
    } else {
      return <p style={{ color: "#d14b69" }}>pngs unavailable</p>;
    }
  }

  return (
    <Fragment>
      <li>
        <ColoredLine text={project} />

        <div className="bot">
          <div className="element">
            <span
              data-tip="session: launched"
              data-for={"launched" + ip + project}
              data-type="info"
            >
              {" "}
              {sessionLaunched}
            </span>
            <ReactTooltip id={"launched" + ip + project} />
          </div>
        </div>
        <div className="bot">
          <div className="element">
            <span
              data-tip="session: proxy"
              data-for={"proxy" + ip + project}
              data-type="info"
            >
              {" "}
              {proxy}
            </span>
            <ReactTooltip id={"proxy" + ip + project} />
          </div>
        </div>
        <div className="bot">
          <div className="element">
            <span
              data-tip="session: ip"
              data-for={"ip" + ip + project}
              data-type="info"
            >
              {" "}
              {ip}
            </span>
            <ReactTooltip id={"ip" + ip + project} />
          </div>
        </div>
        <div className="bot">
          <div className="element">
            <span
              data-tip="session: type"
              data-for={"type" + ip + proxy + sessionLaunched}
              data-type="info"
            >
              {type}
            </span>
          </div>
          <ReactTooltip id={"type" + ip + proxy + sessionLaunched} />
        </div>
        <div className="bot">
          <div className="element">
            <span
              data-tip="session: status"
              data-for={"status" + ip + proxy + sessionLaunched}
              data-type="info"
            >
              {status}
            </span>
          </div>
          <ReactTooltip id={"status" + ip + proxy + sessionLaunched} />
        </div>
        <div className="symlinks">{renderSymlinks()}</div>
        <br />
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <span
                  data-tip="stats: hour"
                  data-for={"hour" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                >
                  H
                </span>

                <ReactTooltip id={"hour" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{hSuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{hErrors}</span>
              </td>
              <td>
                <span>{hBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{hTotal}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span
                  data-tip="stats: dayX"
                  data-for={"day" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                >
                  D
                </span>

                <ReactTooltip id={"day" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{dSuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{dErrors}</span>
              </td>
              <td>
                <span>{dBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{dTotal}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span
                  data-tip="stats: yesterday"
                  data-for={"yesterday" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                >
                  Y
                </span>

                <ReactTooltip id={"yesterday" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{ySuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{yErrors}</span>
              </td>
              <td>
                <span>{yBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{yTotal}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span
                  data-tip="stats: week"
                  data-for={"week" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                >
                  W
                </span>
                <ReactTooltip id={"week" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{wSuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{wErrors}</span>
              </td>
              <td>
                <span>{wBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{wTotal}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span
                  data-tip="stats: month"
                  data-for={"month" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                >
                  M
                </span>

                <ReactTooltip id={"month" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{mSuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{mErrors}</span>
              </td>
              <td>
                <span>{mBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{mTotal}</span>
              </td>
            </tr>
            <tr>
            <td>
              <span
                data-tip="stats: lastMonth"
                data-for={"lastMonth" + ip + project}
                data-type="info"
                style={{ color: "#ef8742" }}
              >
                L
              </span>

              <ReactTooltip id={"lastMonth" + ip + project} />
            </td>
            <td>
              <span style={{ color: "#1aa385" }}>{lSuccesses - mSuccesses}</span>
            </td>
            <td>
              <span style={{ color: "#d14b69" }}>{lErrors-mErrors}</span>
            </td>
            <td>
              <span>{lBounced-mBounced}</span>
            </td>
            <td>
              <span style={{ color: "#ef8742" }}>{lTotal-mTotal}</span>
            </td>
          </tr>

            <tr>
              <td>
                <IoRadioButtonOffOutline
                  data-tip="stats: total"
                  data-for={"total" + ip + project}
                  data-type="info"
                  style={{ color: "#ef8742" }}
                />
                <ReactTooltip id={"total" + ip + project} />
              </td>
              <td>
                <span style={{ color: "#1aa385" }}>{bSuccesses}</span>
              </td>
              <td>
                <span style={{ color: "#d14b69" }}>{bErrors}</span>
              </td>
              <td>
                <span>{bBounced}</span>
              </td>
              <td>
                <span style={{ color: "#ef8742" }}>{bTotal}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </Fragment>
  );
}

export default Project;
