import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Servers from "./components/servers/servers";
import Login from "./components/login/login";
import "./App.css";

export default function App() {
  const [token, setToken] = useState();

  if(!token) {
    return <Login setToken={setToken} />
  }
  return (
    <Router>
      <div>
        <nav className="navbar" style={{ background: "#13162f" }}>
          <Link to="/" className="navbar-brand" style={{ color: "#EF8742" }}>
            HTE Monitor
          </Link>
          <div>
            <ul className="nav">
              <li className="nav-item">
                <Link
                  to="/servers"
                  className="nav-link"
                  style={{ color: "#EF8742" }}
                >
                  Servers
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <Switch>
        <Route path="/">
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col">
              <Servers />
            </div>
          </div>
        </div>
      </Route>
          <Route path="/servers">
            <div className="container-fluid">
              <div className="row mt-5">
                <div className="col">
                  <Servers />
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
