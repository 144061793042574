import React, { Fragment } from "react";

import { ImClock } from "react-icons/im";
import { CgSmartphoneRam, CgDrive } from "react-icons/cg";
import { AiFillChrome } from "react-icons/ai";
import { GiCpu } from "react-icons/gi";
import { BsTerminal } from "react-icons/bs";
import { VscDebugRestart } from "react-icons/vsc";
import Project from "../project";
import { timeConverter } from "../../helpers";
import ReactTooltip from "react-tooltip";

function Server({
  hostname,
  ip,
  serverTime,
  uptime,
  cores,
  freeMemory,
  usedMemory,
  hardDrives,
  processes,
  chromeProcesses,
  _id,
  projectsLogs,
}) {
  return (
    <Fragment>
      <li className="card">
        <div className="cart-name">
          <img src="/svg/server.svg" alt="serv-img" />
          <div>
            <span
              data-tip="server: name"
              data-for={"server" + ip}
              data-type="info"
            >
              {hostname}
            </span>
            <ReactTooltip id={"server" + ip} />
            <br />
            <span data-tip="server: ip" data-for={"ip" + ip} data-type="info">
              {ip}
            </span>
            <ReactTooltip id={"ip" + ip} />
          </div>
        </div>
        <div className="more">
          <div
            className="element"
            data-tip="server: time"
            data-for={"time" + ip}
            data-type="info"
          >
            <ImClock />

            <span> {serverTime}</span>
          </div>
          <ReactTooltip id={"time" + ip} />
        </div>
        <div className="more">
          <div
            className="element"
            data-tip="server: uptime"
            data-for={"uptime" + ip}
            data-type="info"
          >
            <VscDebugRestart />
            <span> {uptime} </span>
          </div>
          <ReactTooltip id={"uptime" + ip} />
        </div>
        <div className="more">
          <div
            className="element"
            data-tip="server: cores"
            data-for={"cores" + ip}
            data-type="info"
          >
            <GiCpu />
            <span> {cores}</span>
          </div>
          <ReactTooltip id={"cores" + ip} />
        </div>
        <div className="more">
          <div
            className="element"
            data-tip="server: ram"
            data-for={"ram" + ip}
            data-type="info"
          >
            <CgSmartphoneRam />
            <span style={{ color: "#1aa385" }}> {freeMemory} </span>
            <span style={{ color: "#d14b69" }}> {usedMemory} </span>
          </div>
          <ReactTooltip id={"ram" + ip} />
        </div>
        <div className="more">
          <div
            className="element"
            className="element"
            data-tip="server: hd"
            data-for={"hd" + ip}
            data-type="info"
          >
            <div>
              {hardDrives.map((hd) => {
                return (
                  <div key={"hd" + ip}>
                    <CgDrive
                      data-tip="hd"
                      data-for={"hd" + ip}
                      data-type="info"
                    />
                    <span> {hd.fs} </span>
                    <span style={{ color: "#1aa385" }}> {hd.available} </span>
                  </div>
                );
              })}
            </div>
            <ReactTooltip id={"hd" + ip} />
          </div>
        </div>
        <div className="more">
          <div
            className="element"
            data-tip="server: processes"
            data-for={"processes" + ip}
            data-type="info"
          >
            <BsTerminal />
            <span> {processes} </span>
            <br />
          </div>
          <ReactTooltip id={"processes" + ip} />
        </div>

        <div className="more">
          <div
            className="element"
            data-tip="server: chromes"
            data-for={"chromes" + ip}
            data-place="top"
            data-type="info"
          >
            <AiFillChrome />
            <span> {chromeProcesses} </span>
          </div>
          <ReactTooltip id={"chromes" + ip} />
        </div>

        {/* BOT STATISTICS */}
        <ul>
          <Project
            key={_id}
            project={projectsLogs.project}
            // session
            sessionLaunched={timeConverter(projectsLogs.log.launched)}
            status={projectsLogs.log.status}
            type={projectsLogs.log.sessionType}
            ip={projectsLogs.log.sessionIp}
            proxy={projectsLogs.log.proxy}
            symlinks={projectsLogs.log.symlinks}
            error={projectsLogs.log.error}
            // hour
            hSuccesses={projectsLogs.log.hour.successes}
            hErrors={projectsLogs.log.hour.errors}
            hBounced={projectsLogs.log.hour.bounced}
            hTotal={projectsLogs.log.hour.total}
            // day
            dSuccesses={projectsLogs.log.day.successes}
            dErrors={projectsLogs.log.day.errors}
            dBounced={projectsLogs.log.day.bounced}
            dTotal={projectsLogs.log.day.total}
            // yesterday
            ySuccesses={projectsLogs.log.yesterday.successes}
            yErrors={projectsLogs.log.yesterday.errors}
            yBounced={projectsLogs.log.yesterday.bounced}
            yTotal={projectsLogs.log.yesterday.total}
            // week
            wSuccesses={projectsLogs.log.week.successes}
            wErrors={projectsLogs.log.week.errors}
            wBounced={projectsLogs.log.week.bounced}
            wTotal={projectsLogs.log.week.total}
            // month
            mSuccesses={projectsLogs.log.month.successes}
            mErrors={projectsLogs.log.month.errors}
            mBounced={projectsLogs.log.month.bounced}
            mTotal={projectsLogs.log.month.total}
            // lastMonth 
            lSuccesses={projectsLogs.log.lastMonth.successes}
            lErrors={projectsLogs.log.lastMonth.errors}
            lBounced={projectsLogs.log.lastMonth.bounced}
            lTotal={projectsLogs.log.lastMonth.total}
            // bot
            bSuccesses={projectsLogs.log.bot.successes}
            bErrors={projectsLogs.log.bot.errors}
            bBounced={projectsLogs.log.bot.bounced}
            bTotal={projectsLogs.log.bot.total}
          />
        </ul>
      </li>
    </Fragment>
  );
}

export default Server;
